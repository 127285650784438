body {
    /*
        We would like to use overflow-y: scroll to prevent "twitching" in the navigation bar when a scrollbar appears.
        However, 'scroll' causes page content clipping issues with md-select (https://github.com/angular/material/issues/8929).
    */
    overflow-y: initial;
}

#main-content {
    outline: none;
}

.nav-main-content {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
}

.login-page .au-nemid .info-links-content {
    color: #fff;
}

/* To make GDPR text visible in login page */
.au-login__container {
    height: 45rem;
}

.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 50%;
    width: 60%;
    margin-left: -30%;
    height: 100%;
    border: none;
}

/* To make default iFrame have the same look and feel as authentication redirect. */
.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 50%;
    width: 60%;
    margin-left: -30%;
    height: 100%;
    border: none;
}

/* Css spinner that loads before javascript is loaded on the page */
.main-spinner {
    display: inline-block;
    position: absolute;
    width: 80px;
    height: 80px;
    top: 50%;
    left: 50%;
    margin-left: -40px;
    margin-top: -40px;
}

.main-spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 6px solid;
    border-radius: 50%;
    animation: main-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0000a0 transparent transparent transparent;
}

.main-spinner div:nth-child(1) {
    animation-delay: -0.45s;
}

.main-spinner div:nth-child(2) {
    animation-delay: -0.3s;
}

.main-spinner div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes main-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

